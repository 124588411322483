.Background {
    height: 100vh;
    background-image: url("../Images/Vector\ \(2\).png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
    /* background-image: url("../Images/Text.png"); */
}

.IntroActtwo-heading{
  font-family: 'DK Display Patrol';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
  color: #68070E;
}

.Intro-act-para{
    font-family: Quicksand;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 37px;
    color: #91181D;
    }

.RectangleImge {
    width: inherit;
    height: 2rem;
}

.ScrollingImg {
    width: 80px;
    position: absolute;
    left: 9%;
    top: -28%;
}

.StarIcon {
    position: absolute;
    top: -17%;
    right: 0%;
    width: 45px;
    height: 46px;
}

.TextIcon {
    position: absolute;
    top: 24%;
    /* width: 19px; */
    /* right: 2.2%; */
    width: 15px;
    /* position: absolute;
    top: 27%;
    right: 1.4%;
    width: 15px; */
    /* position: fixed;
    top: 4%;
    right: 17.1%;
    width: 15px;
    background: red; */
}

.flex {
    display: flex;
    justify-content: end;
}
.LeftBox {
    position: absolute;
    top: -25%;
}

/* .
{
    position: absolute !important;
    top: -25% !important;
} */

/* .Leftbox {
    
    width: 4rem;
    height: 4rem !important;
    background-color: orangered !important;
    border-radius: 15px !important;
    left: 105px !important;
    top: -2px !important;
    margin-left: 6px;
} */

.ArrowLeft {
    width: 2rem !important;
    height: 3rem !important;
    cursor: pointer;
}

.TextImg {
    width: inherit !important;
}



.Card {
    border: 5px solid brown !important;
    border-radius: 20px !important;
    background-color: white;
}










.disappear{
    visibility: hidden;
}
.centered-card{
    display: flex;
    justify-content: center;

    .grid-six {
        display: grid;
        grid-gap: 40px 100px;
        grid-template-columns: auto auto;
        padding: 30px;
        margin-top: 55px ;
        margin-bottom: 58px ;


        .face-image-selected {
            width: 70px;
            height: 70px;
            border: 3px solid brown;
            border-radius: 50%;
            padding: 10px;
            cursor: pointer;
        }

        .face-image {
            width: 70px;
            height: 70px;
            cursor: pointer;
        }
    
    }

    .grid-three-alt{
        display: grid;
        grid-gap: 0px 100px;
        grid-template-columns: auto auto auto;
        padding: 30px;
        text-align: center;
        
        .desstination-face-cont{
            .face-image-destination{
                width: 130px;
                height: 130px;
                cursor: pointer;
            }

            .small-star{
                width: 50px;
            }

            .emotion-names{
                text-transform: capitalize;
                font-weight: 600; 
             }
        }
    }
}

.FooterFlexButton{
   
   width: 7rem;
}

