@import '../../../assets/scss/abstracts/abstracts';

.ProgressBar {
    border: 4px solid brown;
    border-radius: 30px;
    height: 30px;
    width: 100%;
    background-color: #DAD4D4;
    .ProgressBar__Progress{
        background: #AD2FC1;
        border-radius: 30px;
        height: 100%;
        width: 0%;
    }
   }
   