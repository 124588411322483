@import "../../../assets/scss/abstracts/abstracts";
@import "../../../assets/scss/abstracts/mixins.scss";

.the-homePage-card {
  .starPic {
    position: relative;
    bottom: 30px;
    right: 20px;
    .completion-container{
      position: relative;
      left:26px;
      top:25px;
      z-index: 100;
      .show-progress{
        display: block;
      }
      .hide-progress{
        display: none;
      }
      .completion-level{
        position: absolute;
        left:-10px;
        text-align: center;
        width:40px;
        height:20px;
        color: $brown;
      }
    }
  }
  .play-button {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .videoButton{
      box-shadow: 1px 2px 3px grey;
      border-radius: 100%;
    }
  }
  .star {
    z-index: 3;
    position: absolute;
    left: 0px;
    top: 0px;
  }
  .The-home-Card {
    border: 5px solid $brown;
    border-radius: 24px;
    max-height: 248px;
    .bg-image {
      max-height: 238px;
    }
  }
  .homeCard-button-container {
    display: flex;
    justify-content: center;
    transform: translateY(-40px);
    width:90%;
    .homebutton-Text{
      width: 100%;
      max-width: 220px;
      font-family: "DK Display Patrol";
    }
  }
}
