@import "../../../assets/scss/abstracts/abstracts";

.left-menu {
  margin-top: 72px;
  z-index: 4;
  max-width: 250px;
  width: 100%;
  left: 0;
  background-color: $white;
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.06);

  .sidebar-logo {
    height: 72px;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
  ul {
    margin-top: 32px;
    list-style: none;
    padding: 0;
    .nav-link {
      color: $secondary-color;
      padding-left: 20px;
      padding-right: 1rem;
      min-height: 50px;
      display: flex;
      align-items: center;
      transition: all 200ms ease-in-out;
      cursor: pointer;
      margin: 4px;
      font-weight: 600;
      font-size: fontSize(16px);
      line-height: 18px;
      background-color: transparent;
      border-radius: 4px;
      i {
        color: $secondary-color;
        font-size: fontSize(22px);
      }
      &:hover,
      &.active {
        background-color: $gold-d8;
        color: $white;
        margin: 4px;
        border-radius: 4px;
        font-weight: 600;
        i {
          color: $white;
        }
      }
    }
  }
  .sidebar-menu {
    display: flex;
    align-items: center;
    .menu-icon {
      height: 24px;
      width: 24px;
    }
    .sidebar-menu-desc {
      margin-left: 16px;
      text-align: left;
    }
  }
  @media (max-width: 1199.98px) {
    .left-menu {
      min-height: initial;
      overflow-y: auto;
      transform: translate(-100%, 0);
      transition: all 0.3s ease;
      &.open {
        transform: translate(0, 0);
      }
    }
  }
}
