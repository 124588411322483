
@import'../../../assets/scss/abstracts/mixins.scss';
.Background {
  height: 100vh;
  background-image: url("../../IntroActivity/Images/Vector\ \(2\).png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
  /* background-image: url("../../IntroActivity/Images/Text.png"); */
}

.Activity-navigation {
  .LeftBox {
    position: absolute;
    top: -25%;
  }

  .ScrollingCar {
    width: 80px;
    position: absolute;
    top: -28%;
  }

  .Progress-StarIcon {
    position: absolute;
    top: -28%;
    right: 0%;
    width: 62px;
    height: 60px;
  }

  // .Progress-TextIcon-S1{
  //     position: relative;
  //     font-family: 'Quicksand';
  //     font-style: normal;
  //     font-weight: 700;
  //     font-size: 18px;
  //     line-height: 22px;
  //     color: white;

  // }

  .adjust-star-number-label {
    position: relative;
    bottom: 25px;
    left: 13px;
    .Progress-TextIcon-S1 {
      position: absolute;
      right: 0px;
      width: 60px;
      height: 25px;
      text-align: center;
      font-family: "Quicksand";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: white;
    }
  }

  .Progress-TextIcon {
    position: absolute;
    top: 22%;
    width: 31px;
    right: 15px;
  }

  .ArrowLeft {
    width: 2rem !important;
    height: 3rem !important;
    cursor: pointer;
  }
}


// (max-width: 1199px) and (min-width: 992px)
@include media-breakpoint-tab-land {
    
  .Activity-navigation .ScrollingCar{
    left: 84%
  }
}


// (max-width: 1399px) and (min-width: 1200px)
@include media-breakpoint-med-desktop {
  .Activity-navigation .ScrollingCar{
    left: 88%
  }
}

// min-width: 1400px
@include media-breakpoint-hd-desktop {
 
  .Activity-navigation .ScrollingCar{
    left: 88%
  }
}
