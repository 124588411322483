@import'../../../assets/scss/abstracts/mixins.scss';
.IntroActThreeText{
font-family: Quicksand;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 30px;
color: #91181D;


}

.Intro-three-headingText{
font-family: DK Display Patrol;
font-style: normal;
font-weight: normal;
font-size: 36px;
line-height: 43px;
color: #91181D;
}


.FlexBox{
    display: flex;
    justify-content: end;
    position: relative;
    top: -82%;
    left: -3%;
}

.EditButton{
    border-radius: 10px !important;
    border: 3px solid brown !important;
    background-color: #F4B331 !important;
}

.FooterFlexButton{
    display: flex;
    justify-content: center;

    .btn{
        background-color: #AD2FC1 !important;
        border: none;
        border-radius: 10px;
    }
}
 

.form-control-IntroAct3{
    border-radius: 20px !important;
    /* border-color: brown; */
    border: 6px solid brown !important;
}



.clear-btn{
    position: absolute !important;
    bottom: -111px !important;
    right: 41px !important;
    z-index: 1 !important;
    border-radius: 10px !important;
    border: 3px solid brown !important;
    background-color: #F4B331 !important;
}

.canvas-col{
    display: flex !important;
    justify-content: center;
    
    .DrawingBox{
    width: 1200px !important;
    }
}


.edit-icon{
    position: absolute;
    top: 70px;
    right: 40px;
    z-index: 9;
    cursor: pointer;
}


@include media-breakpoint-hd-desktop {
   .introthree-topward{
       position: absolute;
       top: 30px;
   }
}



// @media screen and (min-width:2200px) {
//     .TextIcon {
//         position: absolute;
//         top: 15%;
//         right: 1.1%;
//         width: 15px;
//     }
    
// }