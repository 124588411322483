

#logoutbtns{
    // background-image: url('../../../assets/images/logououtbtn.png');
    // padding: 20px 20px 14px 20px;
    background-color: transparent;
    border:none;
    &:focus{
        outline: none;
    }
}