@import "../abstracts/abstracts";

.login-wrapper {
  .left-area {
    max-height: 100vh;
  }
  .login-area {
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    background-color: #fffcf4;
  }
  .login-left-area {
    height: 100%;
  }
  .center-view-login {
    display: flex;
    align-items: center;
    flex-direction: column;
    .card {
      margin: auto 0;
      width: 100%;
      border-style: none;
      justify-content: center;
      align-items: center;
      background-color: #fffcf4;
      label {
        font-size: fontSize(14px);
        line-height: 16px;
        color: $secondary-color;
        padding-top: 20px;
        font-weight: 500;
      }
      .input-group {
        .form-control {
          display: block;
          padding: 0 28px 0 12px;
          border: 0.5px solid #cfcfcf;
          background-color: #fcfdfe;
          height: 50px;
          font-size: fontSize(16px);
          border-radius: 5px !important;
          width: 100%;
        }
        .input-group-addon {
          position: absolute;
          top: 15px;
          right: 12px;
          z-index: 9;
        }
        input::placeholder {
          font-size: fontSize(16px);
          line-height: 27px;
          color: #cacccf;
        }
      }
    }
    @include media-breakpoint-mobile-port {
      .card {
        min-width: 280px;
        padding: 10px;
      }
    }
  }
}
