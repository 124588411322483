
@import'../../../assets/scss/abstracts/mixins.scss';
.Activity-heading-text{
    font-family: DK Display Patrol;
font-style: normal;
font-weight: normal;
font-size: 36px;
line-height: 43px;
display: flex;
align-items: center;
text-align: center;
color: #68070E;
}
.activity-paragraph{
    font-family: Quicksand;
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 37px;

color: #91181D;
}

.special-text{
    font-size: 30px;
    line-height: 37px;
    font-family: Quicksand;
    font-weight: 100;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.BoxImage{
    border: 10px solid brown;
    border-radius: 20px;
}

.BoxCol{
    display: flex !important;
    justify-content: left !important;
}

.chatIcon{
    display: flex;
    justify-content: center;
    position: relative;
    left:0px;

}

.chatIconImage{
    position: absolute;
    top: -24%;
    width: 340px;
}


.chatIconImage1{
    position: absolute;
    top: 42%;
    padding-left: 72px;
}

.chatIcon-one{
    position: absolute;
    left:200px;
    bottom:400px;
}

.leftChatIconImage{
    position: absolute;
    left: 29px;
    bottom: 16px;
}

.ScrollingCar{
    width: 80px;
    position: absolute;
    left: 7%;
    top: -28%;
}

.activity-one-footerBtn{
    position: absolute;
    bottom: 40px;
}

.actone-topward{
    position: absolute;
    bottom: 30px;
}

.actvity-one-hecho-validator{
    padding: 0px 23px;
}

@include media-breakpoint-tab-land {
    .Activity-heading-text {
        font-size: 26px;
    }
    .special-text{
        font-size: 20px;
    }
    .activity-paragraph{
        font-size: 20px;
        // margin-left: 20rem !important;
    }

    .chatIcon{
        left: 34px;
    }
    .chatIcon-one{
        left: 191px;
    }
    .actone-topward{
        right: 25px;
    }
  }

  @include media-breakpoint-med-desktop{
      .Activity-heading-text{
          margin-left: 3rem;
      }
      .activity-paragraph{
        margin-left: 3rem;
    }
  }

  @include media-breakpoint-hd-desktop {
    .Activity-heading-text{
        margin-left: 3rem;
    }
    .activity-paragraph{
        margin-left: 3rem;
    }
  }