


@import'../../../assets/scss/abstracts/mixins.scss';
.Box{
    height: 32rem;
    padding-left: 20px;
    border-radius: 35px !important;
    border: 10px solid brown !important;
    background-color: white;
}

.blur-background{
    filter: blur(5px);
}


.IntroText{
    font-family: Quicksand;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    color: #91181D;
    
}

.SectionImg{
    width: inherit !important;
    height: 482px !important;
    padding-right: 51px !important;
    padding-left: 27px;
    padding-top: 26px;
}

.modal-btn{
    width: 152px;
    height: 64px;
    position: absolute;
    top: 74%;
    left: 37%;
}

.small-line-one{
    width: 12px;
    height: 4px;
    position: absolute;
    background: antiquewhite;
    border-radius: 15px;
    /* left: 24%; */
    /* padding-left: 2px; */
    margin-left: 10px;
    top: 20%;
}

.small-line-two{
    width: 20px;
    height: 4px;
    position: absolute;
    background: antiquewhite;
    border-radius: 15px;
    /* left: 24%; */
    /* padding-left: 2px; */
    margin-left: 29px;
    top: 20%;
}
.introact-five-hecho-validator{
    padding: 0px 25px;
}


/* ----------------------Media-Query---------------------------- */

@include media-breakpoint-med-desktop {
    .introfive-topward{
        position: absolute;
        top: 30px;
        // padding-bottom: 28px;
    }
    .boy-spot{
        .introAct-boyspotone{
       margin-left: 31px !important;
   }
   .introAct-boyspottwo{
       margin-left: 55px !important;
   }
   .introAct-boyspotthree{
       margin-left: 58px !important;
   }
   .introAct-boyspotfour{
       margin-left: 35px !important;
   }
  }

  .girl-spot{
    //   .introAct-girlspotfour{
    //       margin-left: 36px !important;
    //   }
  }


  }

  @include media-breakpoint-hd-desktop {
   
    .introfive-topward{
        position: absolute;
        top: 30px;
        // padding-bottom: 28px;
    }
    
}


  @include media-breakpoint-tab-land {
    .Box{
        height: 27rem;
    
    .SectionImg{
        height: 407px !important;
    }

}

.AnimalImg-IntroActivity4{
    height: 31.2rem;
}

    .boy-spot{
        .introAct-boyspotone{
       margin-left: 15px !important;
   }
   .introAct-boyspottwo{
       margin-left: 26px !important;
   }
   .introAct-boyspotthree{
       margin-left: 30px !important;
   }
   .introAct-boyspotfour{
       margin-left: 13px !important;
   }
   .introAct-boyspotfour{
    margin-left: 13px !important;
}

.introAct-boyspotfive{
    margin-left: -7px !important;
}
  }

  .girl-spot{

    .introAct-girlspotone{
        left: 30% !important;
    }

     .introAct-girlspottwo{
        left: 34% !important;
    }

    .introAct-girlspotthree{
        left: 34% !important;
    }

   
      .introAct-girlspotfour{
          left: 38% !important;
      }

      .introAct-girlspotFive{
        left: 27% !important;
    }
  }
   
  }