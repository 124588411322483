@import "../../../assets/scss/abstracts/abstracts";
@import "../../../assets/scss/abstracts/mixins.scss";

.story-modal-popup {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 500px;
  height: 400px;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    0deg,
    #f4e795 -28.08%,
    #f4b331 32.96%,
    #f4ad31 34.89%,
    #f48631 49.67%,
    #f46e31 61.46%,
    #f46531 68.7%
  );
  border: 10px solid #91181d;
  border-radius: 40px;
  padding: 10px;
  .animal-image-container {
    position: absolute;
    top: -80px;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;
  }
  .story-Modal-inner {
    width: 100%;
    max-width: 460px;
    height: 360px;
    background: #f4e795;
    border-radius: 25px;
    .chakra-popup {
      position: absolute;
    }
    .story-text-labels {
      color: $brown;
      font-family: DK Display Patrol;
      width: 100%;
      // max-width: 340px;
      line-height: 42px;
      position: relative;
    }
    .has-respon {
      width: 100%;
      max-width: 200px;
      color: $black;
      line-height: 25px;
      position: relative;
      top: 10px;
    }
  }
}
