@import "../../../assets/scss/abstracts/abstracts";

.normal-input {
  label {
    font-size: fontSize(14px);
    color: $black-25;
    line-height: 19px;
  }
  .form-control {
    width: 100%;
    height: 74px;
    font-size: fontSize(16px);
    color: $text-black-1a;
    background-color: #91181D;
    border: 5px solid #F48131;
    border-radius: 15px;
    transition: all 0.25s ease-in-out 0s;
    &:disabled {
      background-color: #91181D;
      cursor: not-allowed;
    }
  }
  input.form-control:focus {
    border-color: $grey-ca !important;
    background-color: #91181D;
  }
  input::placeholder {
    font-size: fontSize(23px);
    font-weight: 400;
    padding-left: 5px;
    color: #F4E795;
  }
}

@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    .normal-input {
      input::placeholder {
        font-size: fontSize(22px);
        font-weight: 400;
        padding-left: 5px;
        color: #F4E795;
      }
    }
  }
}