@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?ngfwpz');
  src:  url('fonts/icomoon.eot?ngfwpz#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?ngfwpz') format('truetype'),
    url('fonts/icomoon.woff?ngfwpz') format('woff'),
    url('fonts/icomoon.svg?ngfwpz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-star:before {
  content: "\e912";
  color: #d8af49;
}
.icon-cross-round:before {
  content: "\e90c";
  color: #d71440;
}
.icon-tick-round:before {
  content: "\e915";
  color: #1bd741;
}
.icon-search:before {
  content: "\e90d";
  color: #cacccf;
}
.icon-arrow-left:before {
  content: "\e90f";
}
.icon-arrow-right:before {
  content: "\e910";
}
.icon-settings:before {
  content: "\e907";
}
.icon-down-arrow:before {
  content: "\e900";
}
.icon-arrow-down:before {
  content: "\e908";
}
.icon-arrow-up:before {
  content: "\e90b";
}
.icon-logout:before {
  content: "\e909";
}
.icon-user-roles:before {
  content: "\e904";
}
.icon-user:before {
  content: "\e905";
}
.icon-dashboard:before {
  content: "\e902";
}
.icon-message:before {
  content: "\e906";
}
.icon-edit:before {
  content: "\e91d";
}
.icon-close-round:before {
  content: "\e90a";
}
.icon-close:before {
  content: "\e911";
  color: #4d4d4d;
}
.icon-tick-transparent:before {
  content: "\e90e";
  color: #fff;
}
.icon-eye:before {
  content: "\e903";
}
.icon-eye-hide:before {
  content: "\e901";
}
.icon-alert-circle:before {
  content: "\e92a";
}
.icon-Delete:before {
  content: "\e914";
}
.icon-bell:before {
  content: "\e966";
}
