@import "../../../assets/scss/abstracts/abstracts";
@import "../../../assets/scss/abstracts/mixins.scss";

.closure-border {
  background-color: $brown;
  padding: 3px 4px 3px 4px;
  border-radius: 22px;
  .the-closure {
    background-image: linear-gradient(
      0deg,
      #f4e795 -28.08%,
      #f4b331 32.96%,
      #f4ad31 34.89%,
      #f48631 49.67%,
      #f46e31 61.46%,
      #f46531 68.7%
    );
    padding: 3px;
    border-radius: 15px;
    .btn-primary {
      border: 1px solid transparent !important;
    }
    #closurebtns {
      background-color: $purple;
      border-radius: 15px;
      width: 100%;
      height: 100%;
      max-height: 55px;
      .double-shadows {
        position: relative;
        bottom: 11px;
        right: 5px;
        .notify-white-shadow {
          position: absolute;
        }
      }
      .closure-btn-Text {
        display: flex;
        justify-content: center;
        padding: 3px;
        font-family: DK Display Patrol;
      }
    }
  }
}
