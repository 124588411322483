.rangeslider__handle{
    width: 43px !important;
    aspect-ratio: 1 / 1 !important;
    border-image: radial-gradient(#AD2FC1 69%, rgba(0, 0, 0, 0) 70%) 84.5% fill / 100% / 0 stretch !important;
    clip-path: polygon(-41% 0px, 50% 91%, 141% 0px) !important;
    /* transform: rotate(
-177deg) !important; */
    /* top: 140px !important; */
    height: 33px !important;
    left: -16px !important;
    z-index: 2 !important;
}

.rangeslider .rangeslider__handle{
    background: none;
}

.rangeslider-vertical{
    height: 450px !important;
    background-color: #DAD4D4 !important;
    border-radius: 50px !important;
}

.custom-labels{
    display: flex !important;
    margin-right: 59px !important;
}

.rangeslider__fill{
    background-color: #F46531 !important;
    border-radius: 50px !important;
}

.rangeslider__label-item::before{
    background-color: #F46E31 !important;
    /* left: 144px !important */
}

.rangeslider__labels{
    color: #F46E31 !important;
    top: 32px !important;
    /* left: -315px !important;
    text-align: center !important;
    border-radius: 50px;
    display: flex !important;
    justify-content: flex-end !important; */
}

.rangeslider__label-item{
    /* display: flex !important;
    justify-content: center !important; */
    /* padding: 0px !important; */
}