// .Background{
//     /* height: 100vh; 
//     background-image: url("../Images/Vector\ \(2\).png");
//     background-repeat: no-repeat;
//     background-size: cover;
//     overflow-x: hidden;
//     background-image: url("../Images/Image6.png"); */
// }
@import'../../../assets/scss/abstracts/mixins.scss';
.RectangleImge{
    width: inherit;
    height: 2rem;
}

.ScrollingImg{
    width: 80px;
    position: absolute;
    left: 9%;
    top: -28%;
    
}

.StarIcon{
    position: absolute;
    top: -17%;
    right: 0%;
    width: 45px;
    height: 46px;
}

.TextIcon{
    position: absolute;
    top: 24%;
    /* width: 19px; */
    /* right: 2.2%; */
    width: 15px
    /* position: absolute;
    top: 27%;
    right: 1.4%;
    width: 15px; */
    /* position: fixed;
    top: 4%;
    right: 17.1%;
    width: 15px;
    background: red; */
}

.flex{
    display: flex;
    justify-content: end;

}
.LeftBox{
    position: absolute;
    top: -25%;
}

/* .RightBox{
    position: absolute !important;
    top: -25% !important;
} */

/* .Leftbox{
   
    width: 4rem;
    height: 4rem !important;
    background-color:#F4B331 !important;
    border-radius: 15px !important;
   
    left: 105px !important;
    top: -2px !important;
    margin-left: 6px;
} */

.ArrowLeft{
    width: 2rem !important;
    height: 3rem !important;
    cursor: pointer;
    
}

.HomeIcon{
    width: 2rem !important;
    height: 2rem !important;
    color: #91181D;
    cursor: pointer;
}

.Rightbox{

    /* width: 5rem!important; */
    width: 54px;
    height: 53px !important;
    border: none !important;
    background-color:#F4B331 !important;
    border-radius: 10px!important;
    /* position: absolute!important; */
    right: 92px!important;
    top: -2px!important;
}


.AnimalImg-IntroActivity4{
    margin-top: 0rem;
    /* margin-left: -6rem !important */
    height: 36rem;
}

.silderColumn{
      display: flex !important;
      
.VerticalLine{
    margin-top: -5rem !important;
    height: 31rem !important;
    padding-right: 40px !important;
    margin-left: 0.6rem !important;
        }
    }

.IntroText{
    font-family: Quicksand;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    color: #91181D;
    
}

.IntroActivity-footer-btn{
    position: absolute;
    top:-35px
}

.IntroActivity4{
    .closure-border{
        position: absolute;
        top: -53px;
    }
}

@include media-breakpoint-tab-land{
    .IntroText{
        font-size: 16px;
    }
    .Introfour-topward{
        display: contents;
      }
}

@include media-breakpoint-med-desktop {
    .Introfour-topward{
        display: contents;
      }
  }


@include media-breakpoint-hd-desktop {
   .IntroActivity4{
    .AnimalImg-IntroActivity4{
           height: 39.2rem;
    }
    .rangeslider-vertical{
        height: 550px !important;
    }
   }
    .Introfour-topward{
      display: contents;
    }
}


