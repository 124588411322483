@import '../../../assets/scss/abstracts/abstracts';
@import '../../../assets/scss/abstracts/mixins.scss';

.home-border{
    background-color: $brown;
    padding: 3px 5px 3px 5px;
    border-radius: 22px;
    .innerhome-border{
        background-image: linear-gradient(0deg, #F4E795 -28.08%, #F4B331 32.96%, #F4AD31 34.89%, #F48631 49.67%, #F46E31 61.46%, #F46531 68.7%);;
        padding: 3px;
        border-radius: 15px;
        #homebtns{
            background-color: $purple;
            border-radius: 15px;
            min-height: 84px;
            .homepage-btN-TexT{
                display: flex;
                justify-content: center;
            }
        }
    }
}
.home-violet-button:disabled {
    opacity: 1 !important;
    cursor: not-allowed;
}
