@import'../../../assets/scss/abstracts/mixins.scss';
.IntroBackground{
    height: 100vh; 
    background-image: url("../Images/IntroActivityBg.png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
    /* background-image: url("../Images/5.png"); */
}

.RectangleImge{
    width: inherit;
    height: 2rem;
}

.InProgressCar{
    width: 80px;
    position: absolute;
    left: 15%;
    top: -28%;
}

.IntroStarIcon{
    position: absolute;
    top: -35%;
    right: 0%;
    width: 62px;
    height: 60px;
}

.IntroTextIcon{
    position: absolute;
    top: 7%;
    /* width: 19px; */
    right: 1.7%;
    width: 31px;
    /* margin-right: 7px */
    
}

.flex{
    display: flex;
    justify-content: end;

}
.IntroLeftBox{
    position: absolute;
    top: -25%;
    
    
}

/* .RightBox{
    position: absolute !important;
    top: -25% !important;
} */

.IntroLeftbox{
    width: 54px;
    height: 53px !important;
    border: none !important;
    margin-top: 2px;
    background-color:#F4B331 !important;
    border-radius: 10px !important;
    /* justify-content: center; */
    /* position: absolute; */
    /* left: 105px !important;
    top: -2px !important */
}

.IntroLeftBoxParent{
    background-image: linear-gradient(0deg, #F4E795 -28.08%, #F4B331 32.96%, #F4AD31 34.89%, #F48631 49.67%, #F46E31 61.46%, #F46531 68.7%);
    width: 65px;
    margin: auto;
    height: 65px;
    border-radius: 15px;
    border: 4px solid brown;
    display: inline-block;
}

.IntroArrowLeft{
    width: 3rem !important;
    height: 3rem !important;
    cursor: pointer;
    
}

.HomeIcon{
    width: 4rem !important;
    height: 3rem !important;
    color:#91181D ;
    cursor: pointer;
   
}

.Rightbox{

    width: 54px;
    height: 54px !important;
    border: none !important;
    margin-top: 2px;
    background-color:#F4B331 !important;
    border-radius: 10px!important;
    /* position: absolute!important; */
    right: 92px!important;
    top: -2px!important
}


.form-control-IntroAct1{
    border-radius: 20px !important;
    /* border-color: brown; */
    border: 6px solid brown !important;
    height: 110px !important;
}

.IntroAct-heading{
color: #68070E;;
font-family: DK Display Patrol;
font-style: normal;
font-weight: normal;
font-size: 36px;
line-height: 43px;

}

.LabelText{
font-family: Quicksand;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 30px;
color: #91181D;


}

.form-control:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
} 

.formColRight{
    /* padding-left: 35px !important; */
    padding-right: 40px !important;
}

.formColLeft{
    padding-left: 40px !important ;
}

.btn-bg{
    background-image: linear-gradient(0deg, #F4E795 -28.08%, #F4B331 32.96%, #F4AD31 34.89%, #F48631 49.67%, #F46E31 61.46%, #F46531 68.7%);
    padding: 3px;
    border-radius: 15px;
}
.btn-outer-border{
    background-color: #91181d;
    padding: 4px;
    border-radius: 22px;
}

.footerButton{
    background-color: #AD2FC1 !important;
    width: 150px !important;
    height: 50px !important;
    font-family: DK Display Patrol !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 24px !important;
   line-height: 29px;
  
    
   
}


.form-control:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
} 

.first-line{
    position: absolute;
    margin-left: 10px;
    top: 20%;
}

.second-line{
    position: absolute;
    margin-left: 21px;
    top: 20%;
}

.introact-one-hecho-validator{
    padding: 0px 25px;
}


/* error text */
.error-text{
    color: red;
}



// @include media-breakpoint-hd-desktop {
   
//   }


// @media screen and (min-width:2200px) {
//     .TextIcon {
//         position: absolute;
//         top: 15%;
//         right: 1.1%;
//         width: 15px;
//     }
    
// }