@import "../scss/abstracts/abstracts";
@import "../scss/abstracts/mixins.scss";

@font-face {
  font-family: "DK Display Patrol";
  src: url("../fonts/DKDisplayPatrol/DK Display Patrol.otf"); /* IE9 Compat Modes */
  /* src: url("Gotham-Book_2.otf") format("embedded-opentype"), IE6-IE8 url("Gotham-Bold.otf") format("otf"); Modern Browsers */
}
// html body {
//     // font-family: Avenir, sans-serif;
//     font-family: "DK Display Patrol" !important;
// }

html,
body,
.maze-game-main {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
  font-family: "DK Display Patrol" !important;
}

/* scrollbar design */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: $purple;
}

::-webkit-scrollbar-thumb:hover {
  background: #333333;
}

button {
  &.btn {
    border-radius: 0;
    font-size: fontSize(16px);
    // line-height: 19px;
    // height: 45px;
    // padding: 0 12px;
    font-weight: 500;
    &.primary-btn {
      background: $primary-color;
      border-radius: 4px;
      font-weight: 500;
      border: 1px solid transparent;
      color: $white;
    }
    &.viewButtonHeader {
      background: transparent;
      border-radius: 4px;
      border: 1px solid $grey-ca;
      color: $primary-color;
      width: 150px;
      padding: 5px;
      font-weight: 500;
    }
    &.viewButtonColorHeader {
      background: $primary-color;
      border: 1px solid transparent;
      border-radius: 4px;
      color: $white;
      width: 150px;
      padding: 5px;
      font-weight: 500;
    }
  }
}

.word-break-normal {
  word-break: normal;
}

.intro-text {
  font-family: "DK Display Patrol";
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  color: $brown;
}
.regular-text {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  color: $brown;
}

.btn:focus,
.btn.focus,
input:focus {
  outline: 0;
  box-shadow: none;
}

.form-control:focus {
  border-color: #fcfdfe !important;
  outline: 0;
  background-color: $white !important;
  box-shadow: none;
  background: none;
}
.text-black {
  color: $black;
}
.cursor-pointer {
  cursor: pointer;
}

//Hide Arrows in input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//Hide Arrows in input
input[type="number"] {
  -moz-appearance: textfield;
}

.text-underline {
  text-decoration: underline;
}

.modal-content {
  border: none;
  padding: 0;
  height: 100%;
}
.Select__dropdown-indicator {
  .icon-up {
    padding-bottom: 2px;
  }
}

.form-control {
  &:disabled {
    border-radius: 4px;
    font-size: fontSize(14px);
    line-height: 18px;
    color: $text-black-1a;
    opacity: 0.9;
    background-color: $white;
    cursor: not-allowed;
  }
}

button:focus {
  outline: none;
}

.border-radius-50 {
  border-radius: 50%;
}
.word-wrap {
  word-wrap: break-word;
}

.border-top {
  border-top: 1px solid #b8bcc5 !important;
}

// login layout starts here
.login-layout {
  width: 100%;
  background: url("../images/animalbackground.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  .login {
    display: flex;
    justify-content: center;
    align-items: center;
    .logincover {
      width: 100%;
      max-width: 515px;
      .login_wing {
        width: 100%;
        max-width: 450px;
        position: relative;
        top: 45px;
      }
      .logincard {
        border: 15px solid $brown;
        background: url("../images/loginBackground.png");
        background-size: cover;
        background-position: center;
        border-radius: 30px;
      }
    }
  }
}
@include media-breakpoint-tab-land {
  .login-layout {
    .login {
      .logincover {
        .login_wing {
          width: 100%;
          max-width: 350px;
          position: relative;
          top: 40px;
        }
        max-width: 400px;
      }
    }
  }
}
@include media-breakpoint-tab-port {
  .login-layout {
    .login {
      .logincover {
        .login_wing {
          width: 100%;
          max-width: 340px;
          position: relative;
          top: 40px;
        }
        max-width: 400px;
      }
    }
  }
}
// login layout ends here

// storytelling layout starts here
.container-fluid {
  margin-left: inherit !important;
  // margin-right: 0px !important;
}
.story_layout {
  width: 100%;
  min-height: 100vh;
  background-image: url("../images/storybackground.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .fullwidth-popup-container {
    .popup-container {
      display: flex;
      justify-content: center;
      .popup-text {
        width: 100%;
        max-width: 350px;
        text-align: center;
        font-family: DK Display Patrol;
        text-transform: uppercase;
        color: #91181d;
      }
    }
    .popup-button-container {
      width: 30%;
    }
  }
  .home_Icon {
    position: relative;
    left: 45px;
  }
  .story2-Banner {
    position: relative;
    .mouselady {
      position: relative;
      bottom: 70px;
      left: 0px;
      z-index: 3;
    }
    .qoutes-wrapper-mouse-content {
      width: 200px;
      height: 185px;
      position: absolute;
      bottom: 550px;
      left: 180px;
      z-index: 200;
      .mouse-qoutesImage {
        width: 285px;
      }
      .thinking-label {
        position: absolute;
        text-align: center;
        width: 325px;
        top: 80px;
        transform: rotate(-13.73deg);
        font-family: "DK Display Patrol";
        color: $brown;
        padding: 0 1.5em;
        height: 195px;
      }
    }
  }
  .story2_Banner_container {
    position: relative;
    .MCmonkey {
      position: relative;
      top: 150px;
      right: 40px;
      z-index: 3;
    }
    .qoutes-wrapper-monkey-content {
      width: 230px;
      height: 185px;
      position: absolute;
      top: 80px;
      left: 135px;
      z-index: 3;
      .qoutes-Image {
        width: 240px;
      }
      .wrapper-text {
        position: absolute;
        text-align: center;
        width: 260px;
        top: 0;
        transform: rotate(-13.73deg);
        font-size: 24px;
        font-weight: 600;
        font-family: "DK Display Patrol";
        color: $brown;
        padding: 0 1.5em;
        height: 185px;
        line-height: 22px;
      }
    }
  }
  .story3_Banner_container {
    position: relative;
    .Duck {
      position: relative;
      left: 0px;
      top: 180px;
      z-index: 3;
    }
    .qoutes-wrapper-duck-content {
      width: 300px;
      height: 155px;
      position: absolute;
      top: 120px;
      left: 170px;
      z-index: 100;
      .qoutesImage-forduck {
        width: 260px;
      }
      .wrapper-text-duck {
        position: absolute;
        text-align: center;
        width: 270px;
        top: -10px;
        right: 30px;
        transform: rotate(-13.73deg);
        font-size: 20px;
        font-weight: 600;
        font-family: "DK Display Patrol";
        color: $brown;
        padding: 0 1.5em;
        height: 185px;
        line-height: 22px;
      }
    }
  }
}

@include media-breakpoint-tab-land {
  .story_layout {
    .story2-Banner {
      .mouselady {
        position: relative;
        bottom: 40px;
        left: 0px;
      }
      .qoutes-wrapper-mouse-content {
        width: 200px;
        height: 185px;
        position: absolute;
        bottom: 380px;
        left: 110px;
      }
    }
    .story2_Banner_container {
      .MCmonkey {
        position: relative;
        top: 110px;
        right: 30px;
      }
      .qoutes-wrapper-monkey-content {
        position: absolute;
        top: 0px;
        left: 75px;
      }
    }
    .story3_Banner_container {
      position: relative;
      .Duck {
        position: relative;
        top: 170px;
        left: 0px;
      }
      .qoutes-wrapper-duck-content {
        position: absolute;
        top: 80px;
        left: 110px;
        .qoutesImage-forduck {
          width: 225px;
        }
        .wrapper-text-duck {
          position: absolute;
          width: 245px;
          right: 65px;
        }
      }
    }
  }
}
@include media-breakpoint-tab-port {
  .story_layout {
    .story2-Banner {
      display: none;
    }
    .story2_Banner_container {
      display: none;
    }
    .story3_Banner_container {
      display: none;
    }
  }
}
@include media-breakpoint-med-desktop {
  .story_layout {
    .story2-Banner {
      .mouselady {
        position: relative;
        bottom: 40px;
        left: 0px;
      }
      .qoutes-wrapper-mouse-content {
        width: 200px;
        height: 185px;
        position: absolute;
        bottom: 470px;
        left: 170px;
        .mouse-qoutesImage {
          width: 240px;
        }
        .thinking-label {
          font-size: 20px;
          width: 275px;
        }
      }
    }
    .story2_Banner_container {
      .qoutes-wrapper-monkey-content {
        position: absolute;
        top: 60px;
        left: 120px;
      }
    }
    .story3_Banner_container {
      position: relative;
      .Duck {
        position: relative;
        top: 95px;
        left: -10px;
      }
      .qoutes-wrapper-duck-content {
        position: absolute;
        top: 40px;
        left: 150px;
        .qoutesImage-forduck {
          width: 240px;
        }
        .wrapper-text-duck {
          position: absolute;
          width: 270px;
          right: 40px;
        }
      }
    }
  }
}
@media screen and (min-width: 2560px) {
  .story_layout {
    .story2-Banner {
      .mouselady {
        position: relative;
        left: 320px;
      }
      .qoutes-wrapper-mouse-content {
        position: absolute;
        bottom: 750px;
        left: 500px;
      }
    }
    .story2_Banner_container {
      .qoutes-wrapper-monkey-content {
        position: absolute;
        left: 250px;
        top: 110px;
        .qoutes-Image {
          width: 350px;
        }
        .wrapper-text {
          position: absolute;
          left: 0px;
          font-size: 34px;
          width: 400px;
          line-height: 40px;
        }
      }
    }
    .story3_Banner_container {
      position: relative;
      .Duck {
        position: relative;
      }
      .qoutes-wrapper-duck-content {
        position: absolute;
      }
    }
  }
}
// storytelling layout ends here

// homepagelayout starts here
.home_LayouT {
  width: 100%;
  min-height: 100vh;
  background: url("../images/storybackground.png");
  background-size: cover;
  background-repeat: no-repeat;
}
// homepagelayout ends here

// homepage starts here
.log_OuT {
  position: relative;
  left: 3px;
  bottom: 3px;
}

.popups-story-btn {
  position: relative;
  font-weight: 400;
  font-size: 24px;
  font-family: DK Display Patrol;
}
.popups-meditation-btn {
  position: relative;
  font-weight: 400;
  font-size: 24px;
  font-family: DK Display Patrol;
}
#hide-animal-circle-logo {
  display: none;
}
#hide-extra-space {
  display: none;
}
#adjustButton {
  position: relative;
  top: 30px;
}
#meditation-para-width-controller {
  max-width: 280px;
}
#storyflow-para-width-controller {
  max-width: 340px;
}
#closureflow-para-width-controller {
  max-width: 400px;
}

.blur-background {
  filter: blur(5px);
}
// homepage ends here

// meditation layout starts here
#meditation-rat-hechobtn-container,
#meditation-monkey-hechobtn-container,
#meditation-duck-hechobtn-container {
  position: absolute;
  bottom: 0px;
  right: 40px;
}
.meditation-Layout-Bg {
  width: 100%;
  min-height: 100vh;
  background-image: url("../images/meditationHomePage.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
// meditation layout ends here

.lockHomeBtnIcons {
  cursor: not-allowed !important;
}
