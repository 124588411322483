@font-face {
  font-family: Avenir;
  src: url("../../fonts/Gotham-Font/AvenirLTStd-Black.otf");
  font-display: swap;
}
@font-face {
  font-family: Avenir;
  src: url("../../fonts/Gotham-Font/AvenirLTStd-Book.otf");
  font-display: swap;
}

@font-face {
  font-family: Avenir;
  src: url("../../fonts/Gotham-Font/AvenirLTStd-Roman.otf");
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400&display=swap');

// @font-face {
//   font-family: "DK Display Patrol";
//   src: url("../fonts/DKDisplayPatrol/DK Display Patrol.otf"); /* IE9 Compat Modes */
//   /* src: url("Gotham-Book_2.otf") format("embedded-opentype"), IE6-IE8 url("Gotham-Bold.otf") format("otf"); Modern Browsers */
// }

.Quicksand_font{
  font-family: 'Quicksand', sans-serif;
}
// .dkdisplay_Patrol{
//   font-family: "DK Display Patrol", sans-serif;
// }