@import "../abstracts/abstracts";

.svg-hover {
  &:hover {
    background-color: $white;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary-color;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary-color;
}

button.btn::focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}

.btn {
  background: #e2f9ec;
  border: 1px solid #f5f6f7;
  box-sizing: border-box;
  border-radius: 4px;
  color: $black;
  font-weight: 500;
  padding: 0.9rem 0;

  &.active {
    background: #189c5c;
    border: 1px solid #f5f6f7;
    color: $white;
  }
}

.modal-backdrop {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  background-color: rgba(255, 255, 255, 0);
  box-shadow: -5px 0 9px 2px rgba(0, 0, 0, 0.08);
  &.show {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    background-color: rgba(255, 255, 255, 0);
    box-shadow: -5px 0 9px 2px rgba(0, 0, 0, 0.08);
  }
}
